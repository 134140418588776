import { Chip, IconButton, Stack, Typography } from "@mui/material";
import { Eye } from "react-feather";
import { useNavigate } from "react-router";
import moment from "moment";
import StateColumn from "../../../components/StateColumn";

const ReservesColumns = (reserves, filterList) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "state",
			label: "Estat",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => <StateColumn state={value} />,
			},
		},
		{
			name: "state.nom",
			label: "Estat",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				display: false,
				filterList: filterList,
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "email",
			label: "E-mail",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "activitat",
			label: "Activitat",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) => {
					return (
						<Stack direction="row" spacing={1}>
							<Chip
								label={
									value?.langs?.[0]?.nom +
									" (" +
									value?.categoria.nom +
									")"
								}
								style={{ fontSize: 11 }}
							/>
						</Stack>
					);
				},
			},
		},
		{
			name: "data",
			label: "Data reserva",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => moment(value).format("DD/MM/YYYY"),
			},
		},
		{
			name: "created_at",
			label: "Data creació",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => moment(value).format("DD/MM/YYYY"),
			},
		},
		{
			name: "preu",
			label: "Total",
			options: {
				filter: false,
				sort: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<Typography variant="body1">
							{reserves[dataIndex]?.cupo
								? reserves[dataIndex].preu -
								  Math.round(
										reserves[dataIndex].preu *
											(Number(
												reserves[dataIndex]?.cupo
													?.descompte
											) /
												100) *
											100
								  ) /
										100
								: reserves[dataIndex].preu}
							€
						</Typography>
					);
				},
			},
		},
		{
			name: "cupo",
			label: "Cupó descompte",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) =>
					value && (
						<Typography variant="body1">
							-{value?.descompte} %
						</Typography>
					),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<IconButton
							onClick={() =>
								navigate(
									`/admin/reserves/view/${reserves[dataIndex].id}`,
									{
										state: reserves[dataIndex],
									}
								)
							}
						>
							<Eye />
						</IconButton>
					);
				},
			},
		},
	];
	return columns;
};

export default ReservesColumns;
