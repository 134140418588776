import * as yup from "yup";
import { useTranslation } from "react-i18next";

const SchemaRes = () => {
	// const { t } = () => {};
	const { t } = useTranslation();

	const schema = yup.object().shape({
		persones: yup.array().of(
			yup.object().shape({
				nom: yup.string().required(t("Nom obligatori")),
				cognom1: yup.string().required(t("Cognom obligatori")),
				cognom2: yup.string().required(t("Cognom obligatori")),
				email: yup.string().when("menor", {
					is: false,
					then: yup
						.string()
						.email(t("Ha de ser un E-mail vàlid"))
						.max(255)
						.required(t("E-mail obligatori")),
				}),
				confirm_email: yup.string().when("menor", {
					is: false,
					then: yup
						.string()
						.required(t("Confirmació obligatòria"))
						.oneOf(
							[yup.ref("email")],
							t("Els e-mails no coincidèixen")
						),
				}),
				dniDarrere: yup.mixed().when("menor", {
					is: false,
					then: yup
						.mixed()
						.test(
							"required",
							t("La fotografia del document és obligatòria"),
							(file) => {
								return file[0] && file[0].size;
							}
						),
				}),
			})
		),
	});

	const schemaReserva = yup.object({
		nom: yup.string().required(t("Nom obligatori")),
		observacions: yup.string(),
		email: yup
			.string()
			.email(t("Ha de ser un E-mail vàlid"))
			.max(255)
			.required(t("E-mail obligatori")),
		phone: yup.string().required(t("Telèfon obligatori")),
		data: yup.string().required(t("Data obligatoria")),
		preu: yup.number().required("El preu és obligatòri").min(1),
		persones: yup
			.number("Ha de ser un valor numèric")
			.typeError("Ha de ser un valor numèric.")
			.min(2, "Mínim 2 persones")
			.max(8, "Més de 8 persones, consultar a Contacte")
			.required(t("Obligatòri")),
	});

	const schemaLloguer = yup.object({
		nom: yup.string().required(t("Nom obligatori")),
		email: yup
			.string()
			.email(t("Ha de ser un E-mail vàlid"))
			.max(255)
			.required(t("E-mail obligatori")),
		phone: yup.string().required(t("Telèfon obligatori")),
	});

	const schemaContacte = yup.object({
		nom: yup.string().required(t("Nom obligatori")),
		missatge: yup.string().required(t("El missatge és obligatori")),
		email: yup
			.string()
			.email(t("Ha de ser un E-mail vàlid"))
			.max(255)
			.required(t("E-mail obligatori")),
	});

	return { schema, schemaReserva, schemaContacte, schemaLloguer };
};

const schemaActivitat = yup.object().shape({
	persones: yup.array().of(
		yup.object().shape({
			nom: yup.string().required("Nom obligatori"),
			cognom1: yup.string().required("Cognom obligatori"),
			cognom2: yup.string().required("Cognom obligatori"),
		})
	),
});

const schemaCupo = yup.object().shape({
	nom: yup.string().required("Nom obligatori"),
	codi: yup
		.string()
		.required("Codi obligatori")
		.min(5, "Ha de ser de, com a mínim, 5 lletres"),
	descompte: yup
		.number("Ha de ser un número")
		.required("Descompte obligatori"),
});

export { SchemaRes, schemaActivitat, schemaCupo };
