import axios from "axios";

const url = "https://pendolguies.covcontrol.net/public/api/";

const Login = async (email, password) => {
	let message;
	await axios
		.post(url + "login", {
			email: email,
			password: password,
			remember_me: true,
		})
		.then((response) => {
			if (response.status === 200) {
				localStorage.setItem("isLoggedIn", true);
				localStorage.setItem("user", JSON.stringify(response.data));
				message = "Login amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "error";
			}
		})
		.catch((error) => {
			message = "error";
			console.log(error);
		});

	return message;
};

const getUser = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "user", config)
		.then((response) => {
			if (response.status === 200) {
				message = response.status;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
			}
		});
	return { message };
};

const logout = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let get_user;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + "logout", config)
		.then((response) => {
			if (response.status === 200) {
				get_user = response.data;
				message = "400";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.setItem("isLoggedIn", false);
			}
		});
	return { get_user, message };
};

const getElements = async (element) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const getElement = async (element, key) => {
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + element + "/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const getElementsAdmin = async (element) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const getElementAdmin = async (element, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element + "/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const createActivitat = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));

	const data = new FormData();

	data.append("nom", values.nom);
	data.append("descripcio", values.descripcio);
	data.append("lloc", values.lloc);
	data.append("epoca", values.epoca);
	data.append("durada", values.durada);
	data.append("situacio", values.situacio);
	data.append("edat", values.edat);
	data.append("material", values.material);
	data.append("inclou", values.inclou);
	data.append("descompte", values.descompte);
	data.append("dificultat", values.dificultat);
	data.append("categoria_id", values.categoria_id);
	data.append("destacada", values.destacada ? 1 : 0);
	data.append("familia", values.familia ? 1 : 0);
	data.append("preus", JSON.stringify(values.preus));
	data.append("langs", JSON.stringify(values.langs));

	if (values.foto) {
		for (const file of values.foto) {
			data.append("foto[]", file);
		}
	}

	let message;

	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};

	await axios.post(url + "activitats", data, config).then((response) => {
		if (response.status === 200) {
			message = "Activitat creada";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al crear";
		}
	});

	return message;
};

const updateActivitat = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));

	const data = new FormData();

	data.append("nom", values.nom);
	data.append("descripcio", values.descripcio);
	data.append("lloc", values.lloc);
	data.append("epoca", values.epoca);
	data.append("durada", values.durada);
	data.append("situacio", values.situacio);
	data.append("edat", values.edat);
	data.append("material", values.material);
	data.append("inclou", values.inclou);
	data.append("preu", values.preu);
	data.append("descompte", values.descompte);
	data.append("dificultat", values.dificultat);
	data.append("categoria_id", values.categoria_id);
	data.append("destacada", values.destacada ? 1 : 0);
	data.append("familia", values.familia ? 1 : 0);
	data.append("preus", JSON.stringify(values.preus));
	data.append("langs", JSON.stringify(values.langs));
	if (values.foto) {
		for (const file of values.foto) {
			data.append("foto[]", file);
		}
	}

	let message;

	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};

	await axios
		.post(url + "activitats/" + key, data, config)
		.then((response) => {
			if (response.status === 200) {
				message = "Activitat actualitzada";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "Error al actualitzar";
			}
		});

	return message;
};

const deleteActivitat = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "activitats/" + key, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al eliminar";
		}
	});

	return message;
};

const createMaterial = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));

	const data = new FormData();

	data.append("nom", values.nom);
	data.append("nom_es", values.nom_es);
	data.append("nom_en", values.nom_en);
	data.append("categories", JSON.stringify(values.categories));
	data.append("talles", JSON.stringify(values.talles));
	data.append("preus", JSON.stringify(values.preus));
	if (values.foto[0]?.name)
		data.append("foto", values.foto[0], values.foto[0].name);

	let message;

	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};

	await axios.post(url + "materials", data, config).then((response) => {
		if (response.status === 200) {
			message = "Material creat";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al crear";
		}
	});

	return message;
};

const updateMaterial = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));

	const data = new FormData();

	data.append("nom", values.nom);
	data.append("nom_es", values.nom_es);
	data.append("nom_en", values.nom_en);
	data.append("categories", JSON.stringify(values.categories));
	data.append("talles", JSON.stringify(values.talles));
	data.append("preus", JSON.stringify(values.preus));
	if (values.foto[0].name)
		data.append("foto", values.foto[0], values.foto[0].name);

	let message;

	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};

	await axios
		.post(url + "materials/" + key, data, config)
		.then((response) => {
			if (response.status === 200) {
				message = "Activitat actualitzada";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "Error al actualitzar";
			}
		});

	return message;
};

const deleteMaterial = async (key) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user.token,
		},
	};
	await axios.delete(url + "materials/" + key, config).then((response) => {
		if (response.status === 200) {
			message = "Eliminat amb èxit";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al eliminar";
		}
	});

	return message;
};

const getPrice = async (persones, key) => {
	let message;
	let result;
	await axios
		.post(url + "preu/" + key, {
			persones: persones,
		})
		.then((response) => {
			if (response.status === 200) {
				result = response.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const contacte = async (values) => {
	let message;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "contacte", values, config).then((response) => {
		if (response.status === 200) {
			message = "Missatge enviat!";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al enviar missatge. Prova-ho més tard";
		}
	});

	return message;
};

const createLloguer = async (values) => {
	const data = new FormData();
	let form;

	data.append("nom", values.nom);
	data.append("email", values.email);
	data.append("data", values.data);
	data.append("observacions", values.observacions);
	data.append("phone", values.phone);
	data.append("preu", values.preu);
	data.append(
		"material",
		JSON.stringify(
			values.material.map((item) => {
				return {
					material_id: item.material.id,
					quant: item.quant,
					talla_id: item.talla.id,
					preu_id: item.preu.id,
				};
			})
		)
	);
	let message;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "lloguers", data, config).then((response) => {
		if (response.status === 200) {
			form = response.data;
			message = "Lloguer reservat";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al reservar. Prova-ho més tard";
		}
	});

	return { form, message };
};

const createReserva = async (values) => {
	let form;
	const data = new FormData();

	data.append("nom", values.nom);
	data.append("email", values.email);
	data.append("data", values.data);
	data.append("observacions", values.observacions);
	data.append("phone", values.phone);
	data.append("preu", values.preu);
	data.append("persones", values.persones);
	data.append("activitat_id", values.activitat_id);
	data.append("cupo_id", values.cupo_id ?? "");

	let message;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
		},
	};

	data.forEach((value, key) => {
		console.log(key, value);
	});

	await axios.post(url + "reserves", data, config).then((response) => {
		if (response.status === 200) {
			form = response.data;
			message = "Creant reserva... Esperi";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al reservar. Prova-ho més tard";
		}
	});

	return { form, message };
};

const changeState = async (values, id, element) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user.token,
		},
	};

	await axios
		.post(url + element + "/" + id, { state_id: values }, config)
		.then((response) => {
			if (response.status === 200) {
				message = "Canvi d'estat finalitzat";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "Error al canviar";
			}
		});

	return message;
};

const dataMaterial = async (data_ll, talla_id) => {
	let count;
	const data = new FormData();

	data.append("data", data_ll);
	data.append("talla_id", talla_id);

	let message;
	let config = {
		headers: {
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
		},
	};

	await axios.post(url + "dataMaterial", data, config).then((response) => {
		if (response.status === 200) {
			count = response.data.data;
			message = "Creant reserva... Esperi";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al reservar. Prova-ho més tard";
		}
	});

	return { count, message };
};

const devolucio = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let resultat;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "retornar/" + key, values, config)
		.then((response) => {
			if (response.status === 200) {
				resultat = response.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { resultat, message };
};

const devolucioLlog = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let resultat;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "retornarLlog/" + key, values, config)
		.then((response) => {
			if (response.status === 200) {
				resultat = response.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { resultat, message };
};

const addElement = async (element, values) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + element, values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const updateElement = async (element, values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + element + "/" + key, values, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = response.data.message;
			}
		})
		.catch((error) => {
			console.log(error);
		});
	return { message };
};

const devolucioEntrada = async (values, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let resultat;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.post(url + "retornarEntrada/" + key, values, config)
		.then((response) => {
			if (response.status === 200) {
				resultat = response.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { resultat, message };
};

const reenviarMail = async (element, key) => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.token,
		},
	};
	await axios
		.get(url + element + "/" + key, config)
		.then((response) => {
			if (response.status === 200) {
				message = response.data.message;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { message };
};

export {
	Login,
	getUser,
	logout,
	getElements,
	getElement,
	createActivitat,
	updateActivitat,
	deleteActivitat,
	createMaterial,
	updateMaterial,
	deleteMaterial,
	getElementsAdmin,
	getPrice,
	contacte,
	createLloguer,
	getElementAdmin,
	createReserva,
	changeState,
	dataMaterial,
	devolucio,
	devolucioLlog,
	addElement,
	devolucioEntrada,
	reenviarMail,
	updateElement,
};
