import React from "react";
import AdminLayout from "./layouts/AdminLayout";
import PublicLayout from "./layouts/PublicLayout";
import { Navigate } from "react-router-dom";
import HomeAdmin from "./views/Admin/HomeAdmin/HomeAdmin";
import LoginView from "./views/Auth/LogInView";
import Home from "./views/Public/Home/Home";
import ActivitatsAdmin from "./views/Admin/ActivitatsAdmin/ActivitatsAdmin";
import ActivitatAdd from "./views/Admin/ActivitatsAdmin/ActivitatAdd";
import ActivitatEdit from "./views/Admin/ActivitatsAdmin/ActivitatEdit";
import MaterialsAdmin from "./views/Admin/MaterialsAdmin/MaterialsAdmin";
import MaterialAdd from "./views/Admin/MaterialsAdmin/MaterialAdd";
import MaterialEdit from "./views/Admin/MaterialsAdmin/MaterialEdit";
import Activitats from "./views/Public/Activitats/Activitats";
import ActivitatSingle from "./views/Public/Activitats/ActivitatSingle";
import Reserva from "./views/Public/Reserva/Reserva";
import Families from "./views/Public/Families/Families";
import QuiSomPage from "./views/Public/QuiSom/QuiSom";
import Contacte from "./views/Public/Contacte/Contacte";
import Escoles from "./views/Public/Escoles/Escoles";
import Lloguer from "./views/Public/Lloguer/Lloguer";
import LloguersAdmin from "./views/Admin/LloguersAdmin/LloguersAdmin";
import LloguerView from "./views/Admin/LloguersAdmin/LloguerView";
import ReservesAdmin from "./views/Admin/ReservesAdmin/ReservesAdmin";
import Success from "./views/Public/Pagament/Success";
import Error from "./views/Public/Pagament/Error";
import ReservaView from "./views/Admin/ReservesAdmin/ReservaView";
import CalendariAdmin from "./views/Admin/CalendariAdmin/CalendariAdmin";
import AvisLegal from "./views/Public/Politica/AvisLegal";
import PoliticaPrivacitat from "./views/Public/Politica/PoliticaPrivacitat";
import CondicionsGenerals from "./views/Public/Politica/CondicionsGenerals";
import Talles from "./views/Public/Lloguer/Talles";
import CondicionsActivitats from "./views/Public/Politica/CondicionsActivitats";
import CondicionsLloguer from "./views/Public/Politica/CondicionsLloguer";
import EntradesAdmin from "./views/Admin/EspaiActiuAdmin/EntradesAdmin/EntradesAdmin";
import EntradaView from "./views/Admin/EspaiActiuAdmin/EntradesAdmin/EntradaView";
import CalendariAdminEAV from "./views/Admin/EspaiActiuAdmin/CalendariAdmin/CalendariAdmin";
import CuponsAdmin from "./views/Admin/CuponsAdmin/CuponsAdmin";
import CupoAdd from "./views/Admin/CuponsAdmin/CuponsAdd";
import CupoEdit from "./views/Admin/CuponsAdmin/CuponsEdit";

const routes = (logged) => [
	{
		path: "/admin",
		element: logged ? <AdminLayout /> : <Navigate to="/login" />,
		children: [
			{
				path: "/admin",
				element: <HomeAdmin title="Home" />,
			},
			{
				path: "/admin/activitats",
				element: <ActivitatsAdmin title="Activitats" />,
			},
			{
				path: "/admin/activitats/add",
				element: <ActivitatAdd title="Afegir activitat" />,
			},
			{
				path: "/admin/activitats/edit/:key",
				element: <ActivitatEdit title="Editar activitat" />,
			},
			{
				path: "/admin/materials",
				element: <MaterialsAdmin title="Materials" />,
			},
			{
				path: "/admin/materials/add",
				element: <MaterialAdd title="Afegir material" />,
			},
			{
				path: "/admin/materials/edit/:key",
				element: <MaterialEdit title="Editar material" />,
			},
			{
				path: "/admin/lloguers",
				element: <LloguersAdmin title="Lloguers" />,
			},
			{
				path: "/admin/lloguers/add",
				element: <MaterialAdd title="Afegir material" />,
			},
			{
				path: "/admin/lloguers/view/:key",
				element: <LloguerView title="Detall Lloguer" />,
			},
			{
				path: "/admin/reserves",
				element: <ReservesAdmin title="Reserves" />,
			},
			{
				path: "/admin/reserves/add",
				element: <MaterialAdd title="Afegir material" />,
			},
			{
				path: "/admin/reserves/view/:key",
				element: <ReservaView title="Detall Reserva" />,
			},
			{
				path: "/admin/calendari",
				element: <CalendariAdmin title="Calendari" />,
			},
			{
				path: "/admin/cupons",
				element: <CuponsAdmin title="Cupons" />,
			},
			{
				path: "/admin/cupons/add",
				element: <CupoAdd title="Afegir Cupo" />,
			},
			{
				path: "/admin/cupons/edit/:key",
				element: <CupoEdit title="Editar Cupo" />,
			},

			// ESPAI ACTIU
			{
				path: "/admin/calendariEAV",
				element: <CalendariAdminEAV title="Calendari" />,
			},
			{
				path: "/admin/entradesEAV",
				element: <EntradesAdmin title="Entrades" />,
			},
			{
				path: "/admin/entradesEAV/:id",
				element: <EntradaView title="Entrades" />,
			},

			// { path: '/*', element: <NotFound /> },
		],
	},
	{
		path: "/",
		element: <PublicLayout />,
		children: [
			{
				path: "/login",
				element: !logged ? <LoginView /> : <Navigate to="/admin" />,
			},
			{ path: "/", element: <Home /> },
			{ path: "/activitats", element: <Activitats /> },
			{ path: "/activitats/:key", element: <ActivitatSingle /> },
			{ path: "/activitats/reserva", element: <Reserva /> },
			{ path: "/families", element: <Families /> },
			{ path: "/qui-som", element: <QuiSomPage /> },
			{ path: "/contacte", element: <Contacte /> },
			{ path: "/escoles", element: <Escoles /> },
			{ path: "/lloguer", element: <Lloguer /> },
			{ path: "/avis-legal", element: <AvisLegal /> },
			{ path: "/politica-privacitat", element: <PoliticaPrivacitat /> },
			{ path: "/condicions-generals", element: <CondicionsGenerals /> },
			{
				path: "/condicions-activitats",
				element: <CondicionsActivitats />,
			},
			{
				path: "/condicions-lloguer",
				element: <CondicionsLloguer />,
			},
			{ path: "/exit/:id", element: <Success /> },
			{ path: "/error/:id", element: <Error /> },
		],
	},
	{ path: "/talles", element: <Talles /> },
];

export default routes;
