/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Checkbox,
	Container,
	FormControlLabel,
	Grid,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaCupo } from "../../../components/Schemas";
import CustomCard from "../../../components/CustomCard";
import CustomTextField from "../../../components/CustomTextField";
import CustomButton from "../../../components/CustomButton";
import { useSnackbar } from "notistack";
import CustomHeader from "../../../components/CustomHeader";
import Title from "../../../components/Layout/Title";
import Loading from "../../../components/Layout/Loading";
import { addElement, getElement, updateElement } from "../../../database/API";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	container: {
		paddingBottom: 50,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	header: {
		borderBottom: "1px solid " + theme.palette.background.light,
	},
}));

const CupoEdit = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const { enqueueSnackbar } = useSnackbar();
	const [cupo, setCupo] = useState();
	const { key } = useParams();

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
		watch,
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schemaCupo),
		defaultValues: { cupo },
	});

	useEffect(() => {
		const get = async () => {
			const { result } = await getElement("cupons", key);
			setCupo(result);
			setValue("actiu", result?.actiu === "1");
			reset(result);
			setLoading(false);
		};
		get();
	}, [key]);

	const enviar = async (values) => {
		console.log(values);
		setLoading(true);
		const { message } = await updateElement("cupons", values, key);
		enqueueSnackbar(message, {
			variant: "success",
		});
		navigate(-1, { state: values });
	};

	return (
		<Box className={classes.container}>
			<Title title="Editar cupó" />
			<Loading loading={loading}>
				<Container maxWidth="xl">
					<Box mt={4}>
						<form onSubmit={handleSubmit(enviar)}>
							<CustomHeader
								title={
									<Typography variant="h3">
										{watch("nom")}
									</Typography>
								}
								button={
									<CustomButton
										type="submit"
										title={"Guardar i sortir"}
									/>
								}
							/>
							<Grid container spacing={5}>
								<Grid item md={8}>
									<CustomCard title="Dades">
										<Grid container spacing={5}>
											<Grid item md={6}>
												<CustomTextField
													name="nom"
													label="Nom"
													type="text"
													errors={errors.nom}
													register={register}
													InputLabelProps={{
														shrink: key
															? true
															: false,
													}}
												/>
											</Grid>
											<Grid item md={6}>
												<CustomTextField
													name="codi"
													label="Codi"
													type="text"
													errors={errors.codi}
													register={register}
													InputLabelProps={{
														shrink: key
															? true
															: false,
													}}
												/>
											</Grid>
										</Grid>
									</CustomCard>
								</Grid>
								<Grid item md={4}>
									<CustomCard title="Descompte (%)">
										<CustomTextField
											name="descompte"
											label="Descompte"
											type="number"
											errors={errors.descompte}
											register={register}
											end={"%"}
											InputLabelProps={{
												shrink: key ? true : false,
											}}
										/>
										<FormControlLabel
											control={
												<Checkbox
													defaultChecked={
														cupo?.actiu === "1"
													}
													onClick={() =>
														setValue(
															"actiu",
															!watch("actiu")
														)
													}
													name={"actiu"}
													{...register("actiu")}
												/>
											}
											label={
												<Box lineHeight={"9px"}>
													<Typography>
														Actiu
													</Typography>
												</Box>
											}
										/>
									</CustomCard>
								</Grid>
							</Grid>
						</form>
					</Box>
				</Container>
			</Loading>
		</Box>
	);
};

export default CupoEdit;
