import { Box, Container, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import Sanefa from "../../../components/Sanefa";
import { getElement } from "../../../database/API";
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import LoadingSkeleton from "../../../components/Layout/LoadingSkeleton";
import ActivitatSkeleton from "../../../components/Skeletons/ActivitatSkeleton";
import { useNavigate, useParams } from "react-router";
import CustomPublicButton from "../../../components/CustomPublicButton";
import Nivell from "../../../components/Nivell";
import familia from "../../../assets/img/familia.svg";
import dades from "../../../assets/img/dades.svg";
import inclou from "../../../assets/img/inclou.svg";
import preu from "../../../assets/img/preu.svg";
import AliceCarousel from "react-alice-carousel";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { ChevronLeft, ChevronRight } from "react-feather";
import Page from "../../../components/Layout/Page";
import { useTranslation } from "react-i18next";

const responsive = {
	0: { items: 1 },
	568: { items: 2 },
	1024: { items: 3 },
};

const ActivitatSingle = () => {
	const classes = useStyles();
	const carousel = useRef().current;
	const [loading, setLoading] = useState(true);
	const [activitat, setActivitat] = useState();
	const { key } = useParams();
	const [items, setItems] = useState([]);
	const handleDragStart = (e) => e.preventDefault();
	let navigate = useNavigate();
	const { t, i18n } = useTranslation();

	useEffect(() => {
		const get = async () => {
			const cat = await getElement(
				"activitats",
				key + "?lang_ab=" + i18n.language
			);
			setActivitat(cat.result);
			setLoading(false);
		};
		get();
	}, [i18n.language]);

	useEffect(() => {
		let list = [];
		activitat?.imatges?.map((img) =>
			list.push(
				<Box width={"100%"} height="250px">
					<Zoom scrollableEl onDragStart={handleDragStart}>
						<img
							src={
								"https://pendolguies.covcontrol.net/public/storage/" +
								img.foto
							}
							onDragStart={handleDragStart}
							alt={t("Imatge")}
							role="presentation"
							height={"100%"}
							width={"100%"}
							style={{
								objectFit: "cover",
							}}
						/>
					</Zoom>
				</Box>
			)
		);
		setItems(list);
	}, [activitat]);

	return (
		<Page
			className={classes.main}
			title={"Pèndol Guies - " + (activitat?.nom ? activitat?.nom : "")}
			descripcio={activitat?.langs?.[0]?.descripcio}
		>
			<Header
				img={
					"https://pendolguies.covcontrol.net/public/storage/" +
					activitat?.imatges?.[0]?.foto
				}
				small
			/>
			<Sanefa color={theme.palette.background.main} />
			<Box className={classes.activitats}>
				<Container>
					<LoadingSkeleton
						loading={loading}
						skeleton={<ActivitatSkeleton />}
					>
						<Box>
							<Typography variant="h1" color="white" mb={5}>
								{activitat?.langs?.[0]?.nom}
							</Typography>
							<Box display="flex" alignItems="center">
								<Box mr={5}>
									<Typography
										variant="h2"
										textTransform="uppercase"
										color={theme.palette.text.secondary}
									>
										{t(activitat?.categoria?.nom)}
									</Typography>
									<Nivell
										invertit
										dificultat={activitat?.dificultat}
									/>
								</Box>

								<Box>
									{activitat?.familia === "1" ? (
										<Tooltip
											title={t("Activitat familiar")}
										>
											<img
												src={familia}
												alt={t("Activitat familiar")}
												width={50}
											/>
										</Tooltip>
									) : (
										""
									)}
								</Box>
							</Box>

							<Typography variant="body1" color="white" my={5}>
								<div
									dangerouslySetInnerHTML={{
										__html: activitat?.langs?.[0]
											?.descripcio,
									}}
								/>
							</Typography>
							<Grid container spacing={5}>
								<Grid item md={4} xs={12}>
									<TitleHeader
										title={t("Dades")}
										icon={dades}
									/>
									<Box p={3}>
										<Dada
											label={t("Època")}
											value={activitat?.langs?.[0]?.epoca}
										/>
										<Dada
											label={t("Durada de l'activitat:")}
											value={
												activitat?.langs?.[0]?.durada
											}
										/>
										<Dada
											label={t("Situació")}
											value={activitat?.situacio}
										/>
										<Dada
											label={t("Edat mínima:")}
											value={
												activitat?.edat +
												" " +
												t("anys") +
												" " +
												"(els menors sempre han d’anar acompanyats d’un adult)."
											}
										/>
										<Dada
											label={t(
												"Material a portar per al client:"
											)}
											value={
												activitat?.langs?.[0]?.material
											}
										/>
									</Box>
								</Grid>
								<Grid item md={4} xs={12}>
									<TitleHeader
										title={t("Inclou")}
										icon={inclou}
									/>
									<Box p={3}>
										<Dada
											label={t("L'activitat inclou:")}
											value={
												<div
													dangerouslySetInnerHTML={{
														__html: activitat
															?.langs?.[0]
															?.inclou,
													}}
												/>
											}
										/>
										<Typography color="white">
											{t(
												"No inclou, transport ni dietes"
											)}
										</Typography>
									</Box>
								</Grid>
								<Grid item md={4} xs={12}>
									<TitleHeader
										title={t("Preus")}
										icon={preu}
									/>
									<Box p={3}>
										{activitat?.preus.map((item, index) => (
											<Dada
												label={
													activitat?.categoria_id !==
													"4"
														? t("de_a_persones", {
																min: item.min,
																max: item.max,
														  })
														: t("Salt nº") +
														  " " +
														  (index + 1)
												}
												value={
													item.preu +
													"€/" +
													(item.unic === "1"
														? t("preu tancat")
														: t("persona"))
												}
											/>
										))}
										{activitat?.categoria_id !== "4" ? (
											<Typography color="white">
												{t("Més persones, a consultar")}
											</Typography>
										) : (
											""
										)}
									</Box>
								</Grid>
							</Grid>
							<Box textAlign={"center"}>
								<CustomPublicButton
									title={t("RESERVA")}
									onClick={() =>
										navigate("/activitats/reserva", {
											state: activitat,
										})
									}
								/>
							</Box>
						</Box>
					</LoadingSkeleton>
				</Container>
			</Box>
			<Sanefa color="white" />
			<Container className={classes.slide}>
				<Typography variant="h1" fontSize={"3rem"} mb={4}>
					{t("Galeria")}
				</Typography>
				<AliceCarousel
					mouseTracking
					items={items}
					controlsStrategy="alternate"
					responsive={responsive}
					disableDotsControls
					infinite
					ref={carousel}
					renderPrevButton={() => {
						return <ChevronLeft className={classes.left} />;
					}}
					renderNextButton={() => {
						return <ChevronRight className={classes.right} />;
					}}
				/>
			</Container>
		</Page>
	);
};

export default ActivitatSingle;

const TitleHeader = ({ title, icon }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			className={classes.header}
		>
			<Typography variant="h1" color="secondary" fontSize={35}>
				{title}
			</Typography>
			<img src={icon} alt={t("Activitat familiar")} width={50} />
		</Box>
	);
};

const Dada = ({ label, value }) => {
	if (value !== "null") {
		return (
			<Box>
				<Typography variant="body1" color="white" fontWeight={800}>
					{label}
				</Typography>
				<Typography variant="body1" color="white" mb={3}>
					{value}
				</Typography>
			</Box>
		);
	}
};

const useStyles = makeStyles((theme) => ({
	main: {
		overflowX: "hidden",
	},
	filters: {
		paddingTop: 100,
		paddingBottom: 180,
	},
	activitats: {
		paddingTop: 100,
		paddingBottom: 250,
		background: theme.palette.background.gradient,
		marginTop: -5,
	},
	buscant: {
		paddingTop: 150,
		paddingBottom: 150,
		textAlign: "center",
	},
	header: {
		border: "1px solid white",
		borderRadius: 50,
		padding: 15,
		paddingLeft: 20,
	},
	label: {
		color: "white",
		fontWeight: 800,
	},
	value: {
		color: "white",
	},
	imageWrap: {
		height: 200,
	},
	slide: {
		paddingBottom: 50,
		paddingTop: 50,
		"& .alice-carousel__stage-item div": {
			width: "100%",
		},
	},
	left: {
		position: "absolute",
		left: -60,
		top: "35%",
		width: 64,
		height: 64,
	},
	right: {
		position: "absolute",
		right: -60,
		top: "35%",
		width: 64,
		height: 64,
	},
}));
