import React, { forwardRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { Fade } from "@mui/material";
import theme from "../../theme";

const Page = forwardRef(
	({ children, title = "", descripcio = "", ...rest }, ref) => {
		const [appear, setApperar] = useState(false);
		useEffect(() => {
			window.scrollTo(0, 0);
			setTimeout(() => {
				setApperar(true);
			}, 300);
		}, []);

		return (
			<div
				ref={ref}
				{...rest}
				style={{ backgroundColor: theme.palette.background.main }}
			>
				<Helmet>
					<title>{title}</title>
					<meta
						name="viewport"
						content="width=device-width, initial-scale=1.0"
					></meta>
					<meta name="description" content={descripcio} />
					<meta name="robots" content={"index, follow"} />
					<meta
						name="keywords"
						content={"pendolguies, pendol, activitats, bergueda"}
					/>
				</Helmet>
				<Fade in={appear} timeout={400}>
					<div
						style={{ overflow: "hidden", backgroundColor: "white" }}
					>
						{children}
					</div>
				</Fade>
			</div>
		);
	}
);

Page.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
};

export default Page;
