import { CheckCircleTwoTone } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Edit } from "react-feather";
import { useNavigate } from "react-router";
import theme from "../../../theme";

const CuponsColumns = (cupons) => {
	const navigate = useNavigate();
	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				display: false,
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "codi",
			label: "Codi",
			options: {
				filter: false,
				sort: true,
				sortOrder: "desc",
			},
		},
		{
			name: "descompte",
			label: "Descompte",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value) => value + " %",
			},
		},

		{
			name: "actiu",
			label: "Actiu",
			options: {
				filter: true,
				sort: true,
				sortOrder: "desc",
				customBodyRender: (value) =>
					value === "1" ? (
						<CheckCircleTwoTone
							color={theme.palette.background.color}
						/>
					) : (
						""
					),
			},
		},
		{
			name: "id",
			label: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<IconButton
							onClick={() =>
								navigate(
									`/admin/cupons/edit/${cupons[dataIndex].id}`,
									{
										state: cupons[dataIndex],
									}
								)
							}
						>
							<Edit />
						</IconButton>
					);
				},
			},
		},
	];
	return columns;
};

export default CuponsColumns;
