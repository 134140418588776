/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	CircularProgress,
	Container,
	Grid,
	IconButton,
	Tooltip,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import CustomCard from "../../../components/CustomCard";
import { getElementAdmin, reenviarMail } from "../../../database/API";
import Loading from "../../../components/Layout/Loading";
import Title from "../../../components/Layout/Title";
import moment from "moment";
import State from "../../../components/State";
import ActivitatAdmin from "../../../components/Activitats/ActivitatAdmin";
import DialogDevolucio from "../../../components/Activitats/DialogDevolucio";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
	},
	container: {
		paddingBottom: 50,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
	header: {
		borderBottom: "1px solid " + theme.palette.background.light,
	},
	talla: {
		border: "1px solid " + theme.palette.background.light,
		borderRadius: 5,
		padding: 10,
		marginBottom: 10,
	},
	line: {
		display: "flex",
		justifyContent: "space-between",
		borderBottom: "1px solid " + theme.palette.background.third + "40",
		paddingTop: 10,
		paddingBottom: 10,
	},
}));

const ReservaView = () => {
	const classes = useStyles();
	const [reserva, setReserva] = useState();
	const [loading, setLoading] = useState(true);
	const { key } = useParams();
	const [loadingEmail, setLoadingEmail] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const get = async () => {
			const { result } = await getElementAdmin("reserves", key);
			setReserva(result);
			setLoading(false);
		};
		get();
	}, []);

	const reenviar = async () => {
		setLoadingEmail(true);
		const { message } = await reenviarMail("reenviarEmailReserva", key);
		enqueueSnackbar(message, {
			variant: "success",
		});
		setLoadingEmail(false);
	};

	return (
		<Box className={classes.container}>
			<Title title="Detall reserva" />
			<Loading loading={loading}>
				<Container maxWidth="xl">
					<Box mt={4}>
						<Grid container spacing={4}>
							<Grid item md={7}>
								<CustomCard title="Activitat">
									<Box>
										<ActivitatAdmin
											activitat={reserva?.activitat}
										/>
									</Box>
								</CustomCard>
							</Grid>
							<Grid item md={5}>
								<CustomCard title="Dades del client">
									<Box className={classes.line}>
										<Typography variant="body2">
											Nom:
										</Typography>
										<Typography variant="body1">
											{reserva?.nom}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											E-mail:
										</Typography>

										<Box
											display={"flex"}
											alignItems={"center"}
										>
											<Typography variant="body1">
												{reserva?.email}
											</Typography>
											<Tooltip title="Reenviar correus">
												<IconButton onClick={reenviar}>
													<ForwardToInboxIcon />
												</IconButton>
											</Tooltip>
											{loadingEmail && (
												<CircularProgress />
											)}
										</Box>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											Telèfon:
										</Typography>
										<Typography variant="body1">
											{reserva?.phone}
										</Typography>
									</Box>

									<Box className={classes.line}>
										<Typography variant="body2">
											Observacions:
										</Typography>
										<Typography variant="body1">
											{reserva?.observacions}
										</Typography>
									</Box>
								</CustomCard>
								<Box my={3} />
								<CustomCard title="Dades de la compra">
									<Box className={classes.line}>
										<Typography variant="body2">
											Estat:
										</Typography>
										<Typography variant="body1">
											<State
												state={reserva?.state}
												id={reserva?.id}
												element="changeState"
											/>
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											ID:
										</Typography>
										<Typography variant="body1">
											{reserva?.unique_id}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											Data compra:
										</Typography>
										<Typography variant="body1">
											{moment(reserva?.created_at).format(
												"DD/MM/YYYY"
											)}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											Nº de persones:
										</Typography>
										<Typography variant="body1">
											{reserva?.persones}
										</Typography>
									</Box>
									<Box className={classes.line}>
										<Typography variant="body2">
											Data de reserva:
										</Typography>
										<Typography variant="body1">
											{moment(reserva?.data).format(
												"DD/MM/YYYY"
											)}
										</Typography>
									</Box>
									{reserva?.cupo && (
										<Box className={classes.line}>
											<Typography variant="body2">
												Cupó:
											</Typography>
											<Typography variant="body1">
												{reserva?.cupo?.nom}
											</Typography>
										</Box>
									)}
									{reserva?.cupo && (
										<Box className={classes.line}>
											<Typography variant="body2">
												Descompte:
											</Typography>
											<Typography variant="body1">
												{reserva?.cupo?.descompte} % (-
												{Math.round(
													reserva?.preu *
														(Number(
															reserva?.cupo
																?.descompte
														) /
															100) *
														100
												) / 100}
												€)
											</Typography>
										</Box>
									)}
									{reserva?.cupo ? (
										<Box className={classes.line}>
											<Typography variant="body1">
												Total:
											</Typography>
											<Typography variant="h2">
												{reserva?.preu -
													Math.round(
														reserva?.preu *
															(Number(
																reserva?.cupo
																	?.descompte
															) /
																100) *
															100
													) /
														100}{" "}
												€
											</Typography>
										</Box>
									) : (
										<Box className={classes.line}>
											<Typography variant="body1">
												Total:
											</Typography>
											<Typography variant="h2">
												{reserva?.preu} €
											</Typography>
										</Box>
									)}
								</CustomCard>
								<Box my={3} />
								<DialogDevolucio reserva={reserva} />
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Loading>
		</Box>
	);
};

export default ReservaView;
