import { Box, Button, Collapse, Container } from "@mui/material";
import { makeStyles, ThemeProvider } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Title from "../../../components/Layout/Title";
import MUIDataTable from "mui-datatables";
import CuponsColumns from "./CuponsColumns";
import TableOptions from "../../../components/TableOptions";
import { getElements } from "../../../database/API";
import { Plus } from "react-feather";
import getMuiTheme from "../../../theme/TableStyle";
import Loading from "../../../components/Layout/Loading";
import CustomButton from "../../../components/CustomButton";

const useStyles = makeStyles((theme) => ({
	container: {
		paddingBottom: 80,
	},
	"@global": {
		html: {
			width: "100%",
			height: "100%",
		},
	},
}));

const CuponsAdmin = () => {
	const classes = useStyles();
	const [cupons, setCupons] = useState([]);
	const [loading, setLoading] = useState(true);
	const columns = CuponsColumns(cupons);
	const [showInactive, setShowInactive] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const get = async () => {
			const { result } = await getElements("cupons");
			setCupons(result);
			setLoading(false);
		};

		get();
	}, []);

	const CustomToolbar = () => {
		return (
			<CustomButton
				onClick={() => navigate("/admin/cupons/add")}
				title={
					<>
						<Plus size={15} /> Nou cupó
					</>
				}
			/>
		);
	};
	const options = TableOptions(CustomToolbar);

	return (
		<Box className={classes.container}>
			<Title title="Cupons" />
			<Container maxWidth="xl">
				<Loading loading={loading}>
					<Box pt={4} spacing={3}>
						<ThemeProvider theme={getMuiTheme()}>
							<MUIDataTable
								data={cupons?.filter((i) => i.actiu === "1")}
								columns={columns}
								options={options}
							/>
						</ThemeProvider>
					</Box>
					<Box spacing={3}>
						<Box>
							<Button
								onClick={() => setShowInactive(!showInactive)}
								size="small"
								variant="contained"
							>
								Mostrar / Amagar inactius
							</Button>
						</Box>
						<Collapse in={showInactive}>
							<ThemeProvider theme={getMuiTheme()}>
								<MUIDataTable
									data={cupons?.filter(
										(i) => i.actiu === "0"
									)}
									columns={columns}
									options={options}
								/>
							</ThemeProvider>
						</Collapse>
					</Box>
				</Loading>
			</Container>
		</Box>
	);
};

export default CuponsAdmin;
